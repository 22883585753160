import { createSlice } from '@reduxjs/toolkit';
import { getAllEntities } from './entityThunk';
// ----------------------------------------------------------------------
const initialState = {
    entities: [],
    getEntitiesStatus: 'idle',
    getEntitiesError: '',
};
const slice = createSlice({
    name: 'entity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllEntities.pending, (state) => {
            state.getEntitiesStatus = 'loading';
        })
            .addCase(getAllEntities.fulfilled, (state, { payload }) => {
            state.getEntitiesStatus = 'succeeded';
            state.entities = payload?.data.filter((e) => e !== 'Notification' &&
                e !== 'VoucherSignature' &&
                e !== 'VoucherMaterial' &&
                e !== 'History');
        })
            .addCase(getAllEntities.rejected, (state, { error }) => {
            state.getEntitiesStatus = 'failed';
            state.getEntitiesError = error?.message || 'An error occured';
        });
    },
});
export default slice.reducer;
