import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const getAllEntities = createAsyncThunk('role/getAllEntities', async (body, thunkApi) => {
    let data;
    try {
        const response = await apiKey.get(`/permissions/entities`);
        data = response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
